import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    public form: FormGroup;
    arr: any = [];
    passwordshow: boolean = false;
    Mobilenum: any; checkNo: any;
    mobiledata: any;
    HomeUrl: any;
    token: any;
    Category: any;
    BranchdropList: any; GST_TreatmentName: any; profiledata: any;
    enableGSTNoField: any; LoginDetails1: any; LoginDetails: any;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(public generalservice: GeneralService, public fb: FormBuilder, public http: HttpClient,
        private router: Router) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
    

            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'


        });

        this.form = this.fb.group({
            CompanyName: ['', Validators.required],
            UserName: ['', Validators.required],
            EMailID: ['', Validators.required],
            Phone1: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(10)]],
            City: ['', Validators.required],
            PWD: ['', Validators.required],
            GST_TreatmentName: ['', Validators.required],
            GSTNo: ['',],
            ApprovalStatus: ['',],
            ContPerson: ['',],
            Phone2: ['',],
            CompanyCode: ['',],
            GSTID: ['',],
            CreditLimit: ['',],
            CreditDays: ['',],
            ZOHO_Books_ID: ['',],

        })

    }



    ngOnInit() {
        this.GetCategory()
        this.getprofile()
        //const initialGSTName = 'Initial GST Name'; // Replace with actual initial value
        //const initialGSTID = 'Initial GST ID'; // Replace with actual initial value
        //this.form.patchValue({
        //    GST_TreatmentName: initialGSTName,
        //    GSTID: initialGSTID
        //});
    }

    onGSTSelect(event: any) {
         
        const selectedGST = event;
        this.form.patchValue({
            GST_TreatmentName: event.value.GST_TreatmentName,
            GSTID: event.value.GSTID
        });
    }

    //getprofile() {
     

    //    this.arr = [{}]; // Initialize arr with an empty object
    //    var UploadFile = new FormData();
    //    UploadFile.append("Param1", this.LoginDetails ? this.LoginDetails[0].Phone1 : ''); 
    //    UploadFile.append("Param2", this.LoginDetails ? this.LoginDetails[0].PWD : ''); 

    //    var url = "api/Azole/Azl_Customer_Login";
    //    this.generalservice.PostData(url, UploadFile).then(data => {
    //        this.profiledata = data;
    //        this.bindFormData(this.profiledata);
    //        this.LoginDetails = this.profiledata;
    //        // Call bindFormData with the received data
    //    }).catch(err => { // Use catch to handle errors
    //        this.generalservice.ShowAlert('ERROR', 'Something went wrong', 'error'); 
    //    });
    //}

    private encodeBase64(input: string): string {
        return btoa(input); // btoa() encodes a string in Base64
    }


    getprofile() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.LoginDetails[0].RegId,
                CreditLimit: 0,
            });

            var UploadFile = new FormData();
           // const phone1 = this.LoginDetails ? this.LoginDetails[0].Phone1 : '';
           // const password = this.LoginDetails ? this.LoginDetails[0].PWD : '';

            // Encode the password to Base64
            //const encodedPassword = btoa(password);

            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {
               
                this.profiledata = data;
                this.GST_TreatmentName = {
                    GST_TreatmentName: this.profiledata[0].GST_TreatmentName,
                    GSTID: this.profiledata[0].GSTID // Assuming GSTID is available in the response
                };
            this.bindFormData(this.profiledata);
           // this.LoginDetails = this.profiledata;



            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    bindFormData(data) {
        if (data) {
            // Decode the Base64 encoded password
            const decodedPassword = atob(data[0].PWD);
            this.form.patchValue({
                CompanyName: data[0].CompanyName,
                UserName: data[0].UserName,
                EMailID: data[0].EMailID,
                Phone1: data[0].Phone1,
                City: data[0].City,
                PWD: decodedPassword,
                GSTNo: data[0].GSTNo,
                GST_TreatmentName: data[0].GST_TreatmentName,
                ApprovalStatus: data[0].ApprovalStatus,
                ContPerson: data[0].ContPerson,
                CompanyCode: data[0].CompanyCode,
                Phone2: data[0].Phone2,
                GSTID: data[0].GSTID,
                CreditDays: data[0].CreditDays,
                CreditLimit: data[0].CreditLimit,
                ZOHO_Books_ID: data[0].ZOHO_Books_ID,
            });
        }
    }

    GetCategory() { 

        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                CreditLimit: 0,


            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/Azole/GST_Treatment_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {  
                this.Category = data; 

            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    filterGroupsSingle4(event: any) {
       
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.BranchdropList = [];
            for (let i = 0; i < this.Category.length; i++) {
                let type = this.Category[i];
                if (type.GST_TreatmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            }
            
            this.BranchdropList = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }
    onGSTTreatmentNameChange(selectedValue: any) {
        // Check if the selected GSTID is 2 or 5 to enable/disable the GST number field
        this.enableGSTNoField = selectedValue === '2' || selectedValue === '5';
    }

   

    onSubmit() {
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            if (
                !this.form.value.CompanyName ||
                !this.form.value.UserName ||
                !this.form.value.EMailID ||
                //   !this.form.value.Phone1 ||
                !this.form.value.City ||
                !this.form.value.PWD ||
                !this.form.value.GST_TreatmentName
            ) {
                this.generalservice.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please fill in all fields', '');
                return;
            }

            const gstValue = this.form.value.GST_TreatmentName;
            const gstID = typeof gstValue === 'object' ? gstValue.GSTID : null;
            const gstName = typeof gstValue === 'object' ? gstValue.GST_TreatmentName : gstValue;
            const phone1Value = this.form.get('Phone1').value;

            // Encode the password before sending it
            const encodedPassword = btoa(this.form.value.PWD);

            this.arr = [];
            this.arr.push({
                RegId: this.LoginDetails[0].RegId,
                CompanyName: this.form.value.CompanyName,
                UserName: this.form.value.UserName,
                EMailID: this.form.value.EMailID,
                Phone1: phone1Value,
                City: this.form.value.City,
                PWD: this.form.value.PWD, // Use the encoded password here
                GST_TreatmentName: this.form.value.GST_TreatmentName,
                GSTID: this.form.value.GSTID,
                GSTNo: this.form.value.GSTNo,
                UserTypeId: 2,
                CreditLimit: this.form.value.CreditLimit,
                CreditDays: this.form.value.CreditDays,
                ApprovalStatus: this.form.value.ApprovalStatus,
                CompanyCode: this.form.value.CompanyCode,
                Phone2: this.form.value.Phone2,
                ContPerson: this.form.value.ContPerson,
                ZOHO_Books_ID: this.form.value.ZOHO_Books_ID,
                Status: true
            });

            var uploadfile = new FormData;
            uploadfile.append("Param", JSON.stringify(this.arr));
            uploadfile.append("Flag", "2");

            var url = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}` // Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, uploadfile, { headers }).subscribe(
                (data: any) => {
                    if (data === "Mobile Exists") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Mobile Number Already Exists', '');
                    } else if (data != "") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Profile Updated Successfully', '');
                        localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                        this.router.navigate(['/navbar']).then(() => {
                            //  window.location.reload();
                        });
                    }
                },
                (err) => {
                    this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                });
        });
    }



    /*check mobile exist or not*/
    CheckMobileNo() {
      
        this.Mobilenum = this.form.get('Phone1').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.Mobilenum);
        UploadFile.append("Param1", "1");
        var url = "api/Azole/Get_Check_Mobile_Email"
        
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
           
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/


}

