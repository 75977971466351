<div id="about" class="about-area ptb-100">
    <br /> <br />
    <div class="container">
        <div class="forms-container">
            <div class="signin-signup">
                <form action="#" class="sign-in-form">
                    <h2 class="title">Sign in</h2>
                    <div class="input-field">
                        <i class="fas fa-phone"></i>&nbsp;
                        <input [formControl]="email" placeholder="Enter Email" type="text"  />
                    </div>
                    <div class="input-field">
                        <i class="fas fa-lock"></i>&nbsp;
                        <input [formControl]="Password" placeholder="Enter Password" type="{{ passwordShow ? 'text' : 'password' }}" />
                        <span class="toggle-password" (click)="togglePasswordVisibility('password')">
                            <i class="fa fas" [ngClass]="{ 'fa-eye': passwordShow, 'fa-eye-slash': !passwordShow }"></i>
                        </span>
                        <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('required')">Password is required</small>
                        <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('minlength')">Password should be at least 6 characters long</small>
                    </div>
                    <div>
                        <a (click)="ForgotPassword()" class="transition pull-right forgotpas" style="cursor: pointer;  float: right;">Forgot password?</a>
                    </div>

                    <input type="submit" value="Login" class="btn solid" (click)="OnSubmit()" />

                </form>
               
            </div>
        </div>

        <div class="modal" id="myModal2" style="margin-top:9rem;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header state modal-primary">
                        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Email</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true"style="color: #f38100">X</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>Email<span style="color:red">*</span></label>
                            <input [(ngModel)]="EMailID" class="form-control Caps" placeholder="Enter Email" type="text"  style="width:450px">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="  submit"  (click)="CheckMobile(EMailID)">Submit</button>
                        <!--<button id="closemodal"class="  submit" type="button" data-dismiss="modal" (click)="Close()">Close</button>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- #endregion -->
        <!-- #region check otp popup for forgot password-->
        <div class="modal" id="myModal3">
            <div class="modal-dialog" role="document">
                <div class="modal-content sss">
                    <div class="modal-header state modal-primary">
                        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true"style="color:#f38100;">X</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>OTP<span style="color:red">*</span></label>
                            <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                        </div>
                        <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                            <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit"class="  submit"  (click)="VerifyOtp(OTP)">Submit</button>
                        <!--<button id="closemodal" type="button" data-dismiss="modal"class="" (click)="Close()">Close</button>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- #endregion -->
        <!-- #region update customer and vendor password-->
        <div class="modal" id="myModal4">
            <div class="modal-dialog" role="document">
                <div class="modal-content sss">
                    <div class="modal-header state modal-primary">
                        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()" style="color:#f38100;"><span aria-hidden="true">X</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>Password<span style="color:red">*</span></label>
                            <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="{{ passwordShow ? 'text' : 'password' }}" style="width:450px">
                            <span class="toggle-password1" (click)="togglePasswordVisibility('password')">
                                <i class="fa" [ngClass]="{ 'fa-eye': passwordShow, 'fa-eye-slash': !passwordShow }"></i>
                            </span>
                        </div>

                        <br>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>Confirm Password<span style="color:red">*</span></label>
                            <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirm password" type="{{ confirmPasswordShow ? 'text' : 'password' }}" style="width:450px">
                            <span class="toggle-password1" (click)="togglePasswordVisibility('confirmPassword')">
                                <i class="fa" [ngClass]="{ 'fa-eye': confirmPasswordShow, 'fa-eye-slash': !confirmPasswordShow }"></i>
                            </span>
                        </div>




                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="  submit" (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>
                        <!--<button id="closemodal" type="button"class="close1" data-dismiss="modal" (click)="Close()">Close</button>-->
                    </div>
                </div>
            </div>
        </div>


        <div class="panels-container">
            <div class="panel left-panel">
                <div class="content">
                    <h3>Welcome to Azolechem</h3>
                    <p style="color:white">
                        Azolechem is a globally recognized leader in providing fine and specialty chemicals
                        across diverse industries such as pharmaceuticals, crop science, biotechnology, healthcare, and chemical companies.
                    </p>
                    <button class="btn transparent" id="sign-up-btn" routerLink="/Registration">
                        Sign up
                    </button>
                </div>
                <img src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png" class="image" alt="" />
            </div>

        </div>
    </div>
</div>
