
import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
declare var $: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

    
    regdata: any;
    totalPrice: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    lineitems: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
   
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    sclname: any;
    Price: any;
    gtprice: any;
    Gstper: any;
    quantity: any; TaxnId: any;
    items: any;
    defaultQuantity = 1;
    gstprice: any;
    Gamount: any;
    Grandtotal = 0;
    HomeUrl: any; token: any;
    profiledata: any;
    discountprice:any =0;
    APPdisprice: any = 0;
    disgrandTotal= 0;
    constructor(public http: HttpClient, public cartService: CartService,

        public generalService: GeneralService, public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
      
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            this.items = this.cartItems
          
        })
        this.cartItems.forEach((item: any) => {
          
            this.subTotal = (item.Price * 1) * (item.Quantity); // Assuming Price is a number
            const gstTax = this.subTotal * (item.GSTTAX / 100);
            this.Grandtotal += this.subTotal + gstTax;
            this.Gamount = this.Grandtotal
            localStorage.setItem('Grantotal', this.Gamount);


        });
        this.APPdisprice = this.Grandtotal * this.loginDetails[0].Discount/100
        this.discountprice = this.Grandtotal- this.APPdisprice
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeUrl = data.Webservice;
        });
      
    }

    ngOnInit(): void {
        
        if (this.loginDetails) {
            this.loginStatus = true;
            this.getCustomerAddress();
            this.getprofile();
        }
        else {
            this.loginStatus = false;
        }
      
    }


    getprofile() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId,
                CreditLimit: 0,
            });

            var UploadFile = new FormData();
            // const phone1 = this.LoginDetails ? this.LoginDetails[0].Phone1 : '';
            // const password = this.LoginDetails ? this.LoginDetails[0].PWD : '';

            // Encode the password to Base64
            //const encodedPassword = btoa(password);

            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.profiledata = data;
                
                
                // this.LoginDetails = this.profiledata;



            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }
    /*
    Below procedtoBuy() method will fire when the user click on proced to by button
     */
    procedtoBuy() {
 
        if (this.LoginDetails) {
            if (this.addressDetails) {
                if (!this.addressId) {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please select or add address for Delivery.', '');
                }
                else {

                    $('#myModal').modal('show');

                }
            } else {
                this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please add address for Delivery.', '');
                this.router.navigate(['/Address']);
            }
        }
    }


    navigatae() {

      
        this.router.navigate(['/Products'], { queryParams: { categoryID: 1 } });
    }



    /*Below deleteFromCart() method will fire when user delete the product from the cart*/
    deleteFromCart(index) {
        this.cartService.removeCartItems(index)

    }
    increaseQuantity(item) {
    
        if (item.Quantity < 10 && item.Quantity < item.Availability) {
            item.Quantity++;
        }// Increase quantity of the clicked item

        // Update quantity for all cart items
        this.cartItems.forEach((cartItem: any) => {
            if (cartItem.PackageId === item.PackageId) {
                cartItem.Quantity = item.Quantity;
            }
        });
        this.subTotal = 0
        this.totalPrice = 0
        this.Gstper = 0;
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            debugger
            this.subTotal += (a.Price * 1) * (a.Quantity)
            this.gstprice = this.subTotal * (a.GSTTAX / 100);

            this.Grandtotal = this.subTotal + this.gstprice;
            this.Gamount = this.Grandtotal
           
            this.APPdisprice = this.Grandtotal * this.loginDetails[0].Discount / 100
            this.discountprice = this.Grandtotal - this.APPdisprice
            localStorage.setItem('Grantotal', this.Gamount);
        })
    }

    decreaseQuantity(item) {
        
        if (item.Quantity > 1) {
            item.Quantity--; // Decrease quantity of the clicked item, ensuring it doesn't go below 1
        }
        
        // Update quantity for all cart items
        this.cartItems.forEach((cartItem: any) => {
            if (cartItem.PackageId === item.PackageId) {
                cartItem.Quantity = item.Quantity;
            }
        });
        this.subTotal = 0
        this.totalPrice = 0
        this.Gstper = 0;
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            debugger
            this.subTotal += (a.Price * 1) * (a.Quantity)
            this.gstprice = this.subTotal * (a.GSTTAX / 100);

            this.Grandtotal = this.subTotal + this.gstprice;
            this.Gamount = this.Grandtotal
         
            this.APPdisprice = this.Grandtotal * this.loginDetails[0].Discount / 100
            this.discountprice = this.Grandtotal - this.APPdisprice
            localStorage.setItem('Grantotal', this.Gamount);
        })
    }

    calculateSubTotal(item) {
        return (item.Price * item.Quantity) + ((item.Price * item.Quantity) * (item.GSTTAX) / 100);
    }
  
    
  
    deleteCartfromTable(index: number, PackageId: any) {
        debugger
        this.cartItems.splice(index, 1); // Removes the item from the cartItems array based on index

        // Loop through cartItems to find and remove the item with matching productId from local storage
        for (let i = 0; i < this.cartItems.length; i++) {
            if (this.cartItems[i].PackageId === PackageId) {
                this.cartItems.splice(i, 1); // Remove the item from cartItems array
                break; // Exit the loop once the item is found and removed
            }
        }

        // Save the updated cartItems array to local storage
        localStorage.setItem("cartItems", JSON.stringify(this.cartItems));

        if (this.cartItems.length === 0) {
            // If cartItems is empty after deletion, clear both "Packagesarr" and "cartItems" from local storage
            localStorage.removeItem("Packagesarr");
            localStorage.removeItem("cartItems");

            // Show a warning alert
            this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'No items in the cart.', '');

            // Redirect to Products page with categoryID 1
            this.router.navigate(['/Products'], { queryParams: { categoryID: 1 } });

            // Clear the cart service
            this.cartService.getProducts();
        }

        // Reload the current page
       // location.reload();
    }




  



    getCustomerAddress() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegId);
        var url = "api/Azole/Get_userdetails_basedon_UserID";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.regdata = data;
                this.plus = false
            }
        });
    }


    PlaceOrder() {
        if (this.profiledata[0].CreditLimit < this.Grandtotal) {
            this.generalService.Alertwarning('', 'You have low credits. Please recharge.', 'warning');


        }

        else if (this.profiledata[0].ApprovalStatus!= 'Approved') {
            this.generalService.Alertwarning('', 'Sign up is not approved by AZOELCHEM.', 'warning');


        }
        else {

            debugger

            this.arr = [];
            this.n = ""
            var d = new Date();
            this.n = d.getTime();

            for (var i = 0; i < this.cartItems.length; i++) {
                this.arr.push({



                    ProductId: this.cartItems[i].Productid,
                    Quantity: this.cartItems[i].Quantity,



                    ZBookingID: this.cartItems[i].ZBookingID,
                    Record_Image: this.cartItems[i].Record_Image,
                    CatalogNo: this.cartItems[i].CatalogNo,
                    Price: this.cartItems[i].Price,
                    PackSize: this.cartItems[i].PackSize,
                    HSNCode: this.cartItems[i].HSNCode,
                    CAS: this.cartItems[i].CAS,
                    ChemicalName: this.cartItems[i].ProductName,
                    GSTTAX: this.cartItems[i].GSTTAX,
                    ItemCode: this.cartItems[i].ItemCode,
                    PackageId: this.cartItems[i].PackageId,


                    //TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
                    TotalPrice: this.Gamount,




                })
            }
            this.lineitems = []
            for (var i = 0; i < this.cartItems.length; i++) {
                // Check if discount is null or undefined, and set it to 0 if so
                let discount = this.loginDetails[0].Discount != null ? this.loginDetails[0].Discount + '%' : '0';

                this.lineitems.push({
                    ProductName: this.cartItems[i].ProductName + '-' + this.cartItems[i].PackSize,
                    Rate: this.cartItems[i].BasePrice,
                    Quantity: this.cartItems[i].Quantity,
                    variant_item_id: this.cartItems[i].VariantID,
                    Discount: discount,
                    NumberOfPacks: this.cartItems.length
                });
            }

            //for (var i = 0; i < this.cartItems.length; i++) {
            //    this.lineitems.push({
            //        ProductName: this.cartItems[i].ProductName + '-' + this.cartItems[i].PackSize,
            //       Rate: this.cartItems[i].BasePrice,
            //        Quantity: this.cartItems[i].Quantity,
            //        variant_item_id: this.cartItems[i].VariantID,
            //        Discount: this.loginDetails[0].Discount+'%',
            //        NumberOfPacks : this.cartItems.length
            //    })
            //}
            
            localStorage.setItem("LineItems", JSON.stringify(this.lineitems))


            localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
            this.router.navigate(['/BuyNow']);

        }
    }

    cancel() {
        $('#myModal').modal('hide');
    }


    getSelectedAddress(addressId: any) {
        this.AID = addressId;
        //console.log(addressId)
    }

    /*
       Below method is used to call the delivery mode whether it is online or offline
    */
    getPaymentType(pType: string) {
        this.PaymentType = pType
    }

    selectmode() {
        debugger
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sandhya
    }






}

