

<div id="about" class="about-area ptb-100" style="padding-bottom:0;">
   

    <div class="container">
        <br />
       
        <!--<div class="marquee-container">
            
            <div class="marquee-content"  style="color:red">
                The website will be undergoing maintenance work. We apologize for any inconvenience this may cause.<br />
                  For pricing inquiries and stock availability,please contact us at info@azolechem.com or +91 733 114 3603.
            </div>
        </div>-->


        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">

                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-search"></i>
                    </span>
                    <input type="search" placeholder="Search by Chemical Name/ CAS No/ Catalog No/ Category" class="search" #searchInput (input)="getProducts(searchInput.value)" />

                </div>
                <div class="search-results-popup" *ngIf="searchInput.value && category !== null">
                    <div class="card" *ngIf="category && category.length > 0" style="cursor:pointer">
                        <div>
                            <ul class="list-group search-results-container">
                                <li class="list-group-item" *ngFor="let item of category"
                                    (click)="navigatingToSearchedProduct(item.Name)">{{ item.Products }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="no-results" *ngIf="category && category.length === 0">
                        No products matched
                    </div>
                </div>


                <div class="content">
                    <h5>"Unlocking Success Together: Azolechem, Your Value-Added Partner"</h5>
                    <p>Azolechem is a globally recognized leader in providing fine and specialty chemicals across diverse industries such as pharmaceuticals, crop science, biotechnology, healthcare, and chemical companies.</p>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>





    </div>
    <svg class="nectar-shape-divider"style="margin-top:-10%;height:100px;width:100%;margin-bottom:-4%" fill="gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path style="opacity: 0.15;" d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
        <path style="opacity: 0.15;" d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
        <path style="opacity: 0.15;" d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
        <!--<path d="M 0 65 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>-->
    </svg>
    <!--<div class="header">
        <div class="container">
            <h1 class="header-text">Azole Chem Private Limited</h1>
            <h2>Azole Chem: Your Trusted Partner in Chemical Solutions</h2>
            <input type="search" placeholder="CAS No/Catalog No/Chemical Name" class="search" />
        </div>
    </div>-->
</div>

<style>
    .marquee-container {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;    
        padding: 10px;
    }

    .marquee-content {
        display: inline-block;
        padding-left: 100%;
        animation: marquee 40s linear infinite;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-100%);
        }
    }
</style>
