import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service';
import { CartService } from '../../Services/cart.service'
@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.scss']
})

export class FavouriteComponent {
    Catno: any; Hsn: any; Casno:any
    productDetails: any;
    token: any; ProductName: any;
    HomeUrl: any;
    selectedItems1: any; selectedItems: any;
    Casnos: any;
    arr: any = []; Updateprice: any;
    favlist: any;
    ApprovalStatus1: any;
    Catalog_No: any;
    Catalogue_Number: any;
    HSN: any;
    CAS_No: any;
    loginDet: any;
    loginDetails: any;
    cartService: any;
    RegId: any;
    selectedItemforCart: any=[];
    logedinUser: any;
  //CRMID: this.CRMID;
    Cartimesforloginusers: any[];
    Packsdeta: any;
    Storage_Temperature: any; Subform_1: any; Synonyms: any; CRMID: any;
    image: any;
    price: any;
    Gst: any;
    constructor(public CartService: CartService, public activeroute: ActivatedRoute, public router: Router, public generalservice: GeneralService, public http: HttpClient,) {
        
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'
            this.ProductName = this.activeroute.snapshot.paramMap.get('Productdetails').replace(/\_/g, " ").trim();
        });

        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.generalservice.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.GetProductDetailsbasedonProductName()
            }
            else {
                //this.cartService.setRelatedproductId(res[0].Productid)
                this.ProductName = res[0].productname;
                this.GetProductDetailsbasedonProductName()

            }
        })
        //this.qunty = this.activeroute.snapshot.
    }
    ngOnInit(): void {

        this.GetFavourite();
    }
     logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }

    

    GetProductDetailsbasedonProductName() {
      
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
           
            var UploadFile = new FormData()
            UploadFile.append("Param", this.ProductName)

            var url = this.HomeUrl + "api/Azole/Productdetails_basedon_productName";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                   
                    this.productDetails = data;
                   
                  
                   
                    this.Gst = this.productDetails[0].Gst
                  
                   

                },
                (err) => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });

    }

    GetFavourite() {
       

        // Call Gettoken and await its completion
        
            this.arr = [];
            this.arr.push({
            RegId: this.loginDetails[0].RegId
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

        var url = "api/Azole/Favourite_CRUD";
        
        this.generalservice.PostData(url, UploadFile).then(data => {
           
            this.favlist = data;
            this.image = this.favlist.Record_Image
            this.price = this.favlist.PackPrice
           
            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });       
    }



    Addtocart(Product:any,Catalogue_Number: any, item: any) {
 
        // Check if item already exists in cart
        const existingItem = this.CartService.cartItemList.find(cartItem => cartItem.PackageId === item.PackageId);
        if (existingItem) {
            // Show warning message
            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'This item is already in the cart.', '');
            return; // Do not add the item again
        }

        // Add the item to the cart
        this.selectedItemforCart.push({
            ProductId:item.ProductId,
            ProductName: this.favlist[0].ProductName,
            ZBookingID:item.Books_ID,
            Record_Image: item.Record_Image,
            CatalogNo:item.Catalogue_Number,
            Price: item.PackPrice,
            PackSize: item.PackName,
            HSNCode: item.HSN,
            CAS: item.CAS_No,
         //   ChemicalName: item.Name,
            ChemicalName: this.favlist[0].ProductName,     
            GSTTAX: this.favlist[0].Gst,
            ItemCode: item.Item_Code,
            PackageId: item.PackageId,
            Quantity: item.Quantity,
            CRMID: this.CRMID,
       //   Books_ID: this.Books_ID
            
        });

        // Insert or add to cart service
        if (this.logedin()) {
            // this.insertCartItems(this.selectedItemforCart);
        } else {
            this.CartService.addtoCart(this.selectedItemforCart[0]);
        }

        // Show success message
        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Added to Cart Successfully.', '');
        this.selectedItemforCart = []; // Clear selected items
    }

    insertCartItems(cartItems) {
       
        this.Cartimesforloginusers = []
        this.Cartimesforloginusers.push(cartItems)
        cartItems[0].UserId = String(this.loginDetails.ID)
        var url = "api/Azole/Insert_Customer_Orders";
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(cartItems));
        this.generalservice.PostData(url, UploadFile).then((data: any) => {

            if (data != null) {

                var object = data.filter(x => x.Productid == cartItems[0].Productid)[0];
                this.CartService.addtoCart(object);
            }
        })
    }

    deleteFavorite(val: any) {
      
        this.arr = [];
        this.arr.push({
            FavId: val,
            
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '3');

        var url = "api/Azole/Favourite_CRUD";
        // Use the token obtained from Gettoken

        // Set the Authorization header with the access token
        //const headers = new HttpHeaders({
        //    'Authorization': `Bearer ${accessToken}` // Prefix "Bearer" is a common convention for JWT tokens
        //});

        // Use HttpHeaders in the request
        this.generalservice.PostData(url, UploadFile).then((data: any) => {
            if (data == "SUCCESS") {
                this.generalservice.ShowAlert('SUCCESS', 'Favourite deleted successfully', 'success');
                this.favlist();
            }
          

              //  this.router.navigate(['/Favourite', { catno: cat, hsnno: hsn, casno: cas }])
            
        })

        // Reload the current page
        location.reload();
    }

}
