import { Component } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

     public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    discus: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    Name: any;
    mobile: any;
    HNo: any;
    Address: any;
    Landmark: any;
    City: any;
    State: any;
    Pincode: any;
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];

    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    Mobile: any;
    RegID: any;
    logindata: boolean;
    schooldata: any;
    subtasklist: any;
    ButtonText: any;
    selectedOption: any;
    ID: any;
    regid: any;
    addid: any;
    datafilter: any;
    users: any;
    //category: any[] = [];
    category: any = [];
    deptList3: any[] = [];
    Categoryname: any;
    Names: any; mobiles: any; HNos: any; Addresss: any; Landmarks: any; Citys: any; States: any; Pincodes: any; selectedAddressType: string; //Categoryname: any;
 
    ACID: any;
    Customername: any;
    Mobileno: any;
    statelist: any; subtasks: any; StateName: any;
    isButtonDisabled: boolean = false;
  
    isValidLength: boolean = true;
    isValidStart: boolean = true;
    token: any;
    HomeUrl: any;
    constructor(public genralservice: GeneralService, private activatedRoute: ActivatedRoute, public http: HttpClient, public fb: FormBuilder, public router: Router,) {

        
        localStorage.removeItem("keepsignin");
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
       
        this.addid = this.activatedRoute.snapshot.paramMap.get("addid")
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'

        });
        if (this.LoginDetails != null) {
            this.logindata = true;
            this.regid = this.LoginDetails[0].RegId
            this.Name = this.LoginDetails[0].UserName
            this.mobile = this.LoginDetails[0].Phone1
            
       
        } else {
            this.logindata = false; 
        }
        this.form = this.fb.group({
            Categoryname: ['', Validators.required],
            StateName: ['',],
        });
    }

    ngOnInit(): void {
        this.getprofile();
        this.getregdetails();
        this.getaddresscat();
        this.GetState();
        
    }

    getprofile() {
        debugger
        var url = "api/Token/Gettoken";
        this.genralservice.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.LoginDetails[0].RegId,
                CreditLimit: 0,
            });

            var UploadFile = new FormData();
            
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; 
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });          
            this.http.post(url, UploadFile, { headers }).subscribe(data => {
                debugger
                this.profiledata = data;

                this.discus = this.profiledata.filter(a => a.RegId == this.LoginDetails[0].RegId)
        
            },
                err => {
                    this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }
    validateMobileNumber() {        
        this.isValidLength = length === 10;
       
        if (length > 10) {
            this.Mobileno = this.Mobileno.slice(0, 10);
        }
        const firstDigit = this.Mobileno.charAt(0);
        this.isValidStart = ['6', '7', '8', '9'].includes(firstDigit);
        if (!this.isValidStart) {
            this.genralservice.ShowAlert('Warning', 'Please enter proper  number start with 6,7,8,9', 'Warning');
        }
        return this.Mobileno.length !== 10;
    }
    getregdetails() {
        debugger
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
       // UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '1');
        var url = "api/Azole/Addressfilterbasedonuid";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.schooldata = data;

            const addidNumber = parseInt(this.addid);
            this.datafilter = this.schooldata.filter((item: any) => item.AID === addidNumber);
            if (this.datafilter.length > 0) {
                this.HNo = this.datafilter[0].HNo;
                this.Address = this.datafilter[0].Address;
                this.Landmark = this.datafilter[0].Landmark;
                this.City = this.datafilter[0].City;
                this.State = this.datafilter[0].State;
                this.Pincode = this.datafilter[0].Pincode;
                this.Categoryname = this.datafilter[0].Categoryname;
                this.Customername = this.datafilter[0].Customername;
                this.Mobileno = this.datafilter[0].Mobileno;
                this.ACID = this.datafilter[0].ACID;
               // this.StateName = this.datafilter[0].StateName;
                this.StateName = {
                    StateName: this.datafilter[0].StateName,
                    State: this.datafilter[0].State // Assuming GSTID is available in the response
                };

            } else {
                // Handle case when no records are found with the given AID
                // You might want to show a message or handle it according to your application logic
            }
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }

    GetState() {
        
        // Call Gettoken and await its completion
        

            
            this.arr = [];
            this.arr.push({
               
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = "api/Azole/Sp_crud_statemaster";
            
            // Use HttpHeaders in the request
            this.genralservice.PostData(url, UploadFile).then(data => {



                this.subtasks = data;
            },
                err => {
                    this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        
    }

    

    filterGroupsSingle7(event: any) {
        
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.statelist = [];
            for (let i = 0; i < this.subtasks.length; i++) {
                let type = this.subtasks[i];
                if (type.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            }
           
            this.statelist = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }



    InsertAdderss(Customername: any, Mobileno: any, HNo: any, Address: any, Landmark: any, City: any, StateName: any, Pincode: any, Categoryname: any, ACID: any) {
       
        if (!Categoryname) {
            this.genralservice.ShowAlert('Warning', 'Category name is missing.', 'Warning');
            return;
        }

        if (!Categoryname.ACID) {
            this.genralservice.ShowAlert('Warning', 'ACID is missing.', 'Warning');
            return;
        }

        if (!Customername) {
            this.genralservice.ShowAlert('Warning', 'Full name is missing.', 'Warning');
            return;
        }

        if (!Mobileno) {
            this.genralservice.ShowAlert('Warning', 'Mobile number is missing.', 'Warning');
            return;
        }

        if (!HNo) {
            this.genralservice.ShowAlert('Warning', 'House number is missing.', 'Warning');
            return;
        }

        if (!Landmark) {
            this.genralservice.ShowAlert('Warning', 'Landmark is missing.', 'Warning');
            return;
        }

        if (!City) {
            this.genralservice.ShowAlert('Warning', 'City is missing.', 'Warning');
            return;
        }

        if (!this.StateName) {
            this.genralservice.ShowAlert('Warning', 'State is missing.', 'Warning');
            return;
        }

        if (!Pincode) {
            this.genralservice.ShowAlert('Warning', 'Pincode is missing.', 'Warning');
            return;
        }
        this.isButtonDisabled = true;
        this.arr = [{
            UID: this.regid,
            Customername: Customername,
            Mobileno: Mobileno,
            HNo: HNo,
            Address: Address,
            Landmark: Landmark,
            City: City,
            State: this.StateName.StateName,
            Pincode: Pincode,
            CategoryID: Categoryname.ACID
        }];

        var obj = {
            attention: Customername,
            address: Address,
            street2: Landmark,
            city: City,
            state: this.StateName.StateName,
            zip: Pincode,
            country: "India",
            fax: "",
            phone: Mobileno,
            ZOHO_Books_ID: this.discus[0].ZOHO_Books_ID || "", // Ensuring ZOHO_Books_ID is handled correctly
        };

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Param1", JSON.stringify(obj));
        uploadfile.append("Flag", '1');

        var url = 'api/Azole/Address_Master1';

        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data != "") {
                let categoryType = 'Delivery/Shipping'; // Default category type

                // Check if 'Categoryname' is set and 'ACID' is 1 for Billing, otherwise, it's Shipping
                if (Categoryname && Categoryname.ACID === 1) {
                    categoryType = 'Billing';
                }

                this.genralservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', `You have successfully added the ${categoryType} Address`, '');
                this.router.navigate(['/Useraddress']);
            } else {
                this.genralservice.ShowAlert('ERROR', 'Failed to add address. Please try again later.', 'error');
                this.isButtonDisabled = false;
            }
        }).catch(error => {
            this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later.', 'error');
            this.isButtonDisabled = false;
        });
    }

    UpdateAdderss(Customername: any, Mobileno: any, HNo: any, Address: any, Landmark: any, City: any, StateName: any, Pincode: any, Categoryname: any, ACID:any) {
        
        const state = this.StateName ? this.StateName.StateName : null;
        if (!Categoryname || !ACID) {
            this.genralservice.ShowAlert('Warning', 'Address type is missing.', 'Warning');
            return;
        }
        this.arr = [{
            // SID: this.selectedOption,
            AID: this.addid,
            UID: this.regid,
            Customername: Customername,
            Mobileno: Mobileno,
            HNo: HNo,
            Address: Address,
            Landmark: Landmark,
            City: City,
            State: state,
            Pincode: Pincode,
            CategoryID: ACID

        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');

        var url = 'api/Azole/Address_Master';

        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
              
                if (this.flag == 2) {
                    //this.genralservice.ShowAlert("ERROR", 'You have successfully signed in as a Address form', 'error');
                } else {
                    this.genralservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Address updated successfully ✔️', '');

                    //this.router.navigate(['/Donersform']).then(() => { window.location.reload(); });
                    this.router.navigate(['/Useraddress']);


                }
            }
        });
    }

    ShowAlert(type: string, message: string, icon: string) {
        const alertElement = document.createElement('div');
        alertElement.classList.add('custom-alert', type);

        if (icon === 'tick') {
            alertElement.innerHTML = `<div class="tick-mark">✔️</div><div>${message}</div>`;
        } else {
            alertElement.innerText = message;
        }

        document.body.appendChild(alertElement);

        setTimeout(() => {
            alertElement.remove();
        }, 3000); // Remove alert after 3 seconds
    }
    Back() {
        this.router.navigate(['/Useraddress']);
    }
    ngAfterViewInit() {
     document.getElementById('preloader').classList.add('hide');
    }
    getaddresscat() {
        debugger
        this.category = []; // Clear the category array
        var UploadFile = new FormData();
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '2');
        var url = "api/Azole/Addressfilterbasedonuid";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.category = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }


    searchCont3(event) {
        debugger
        let filtered: any[] = [];
        let query = event.query.toLowerCase();
        this.deptList3 = this.category.filter(country =>
            country.Categoryname.toLowerCase().indexOf(query) === 0
        );
    }




}




