<div id="about" class="about-area ptb-100">
    <div id="loader" style="display: none;">
        <div class="spinner"></div>
    </div>
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products Details</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;"> <i class="fas fa-home"></i> Home /</a>
                </li>
                <li class="breadcrumb-item" style="color:white;" (click)="GetProductsByCategory1(productDetails[0].CategoryID)">
                    Products /
                </li>
                <!-- Display category name obtained from localStorage -->
                <li style="color:white;" (click)="GetProductsByCategory1(productDetails[0].CategoryID)">
                    {{ getCategoryNameFromLocalStorage() }} /
                </li>

                <li class="" style="color:white;">Products Details</li>
            </ul>


        </div>
    </nav>

    <div class="container">
        <div class="row">
            <div class="col-lg-4 ">
                <div class="prodd">Product Details</div>
                <div class="contact-form">
                    <div class="loader" *ngIf="loading3"></div>
                    <div class="row">
                        <div class="col-lg-6 ">
                            <!--<div *ngIf="item.Structure != null ||item.Structure != '' ">routerLink="/MyOrders"
                                <img src="{{HomeUrl+item.Structure}}" class="pro_img" alt="Scikem" *ngIf="item.Structure">
                            </div>-->
                            <figure>  <img src="{{HomeURL+Record_Image}}" class="card_image" alt="DB" *ngIf="Record_Image"></figure>
                        </div>
                        <!--<div class="col-lg-6 text-right" (click)="order(Catalogue_Number, HSN, CAS_No)">
                            <i class="fas fa-bookmark" ></i>

                        </div>-->
                        <!--<div class="col-lg-6 text-right">
                            <i *ngIf="!FavorateItem" class="far fa-bookmark" (click)="AddFav(Catalogue_Number, HSN, CAS_No, Quantity, PackSize, PackName)"></i>
                            <i *ngIf="FavorateItem" class="far fa-bookmark" (click)="AddFav(Catalogue_Number, HSN, CAS_No, Quantity, PackSize, PackName)"></i>

                        </div>-->
                        <!--<div class="col-lg-6">
                            <h6 class="mail">Sales@azolechem.com</h6>
                        </div>-->
                        <div class="col-lg-12">
                            <h6>{{Name}}</h6>
                        </div>
                    </div> <br />
                    <div class="row Catlog">
                        <div class="product_bg" *ngIf="Catalogue_Number">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Catalogue Number</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class=" casn">{{Catalogue_Number}} </div>
                                </div>

                            </div>
                        </div>
                        <div class="product_bg" *ngIf="Item_Code">
                            <div class="row">

                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Item Code</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Item_Code}}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="product_bg" *ngIf="CAS_No">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">CAS Number</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class=" casn">{{CAS_No}} </div>
                                </div>

                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Synonyms">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Synonym </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Synonyms}}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="product_bg" *ngIf="HSN">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">HSN Code</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{HSN}}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="product_bg" *ngIf="Molecular_Formula">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Molecular Formula </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Molecular_Formula}}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Molecular_Weight">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Molecular Weight </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Molecular_Weight}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Purity">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Purity </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Purity}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product_bg" *ngIf="NLT_Purity">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">NLT Purity</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{NLT_Purity}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="product_bg" *ngIf="Variants">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Variants</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Variants}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product_bg" *ngIf="Stock_Variant">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Stock Variant</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Stock_Variant}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Handling_And_Storage">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Storage </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Handling_And_Storage}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Density">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Density </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Density}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <hr />
                        <h5 style="color:orangered">Specifications</h5><hr /><br />
                        <div class="product_bg" *ngIf="Accidental_Release_Mesaures">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Accidental Release nMesaures </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Accidental_Release_Mesaures}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product_bg" *ngIf="Disposal_Considerations">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Disposal Considerations </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Disposal_Considerations}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product_bg" *ngIf="Exposure_Guidelines">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Exposure Guidelines </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Exposure_Guidelines}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product_bg" *ngIf="First_Aid_Measures">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">First Aid Measures </div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{First_Aid_Measures}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product_bg" *ngIf="Stability_And_Reactivity">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Stability And Reactivity</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Stability_And_Reactivity}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product_bg" *ngIf="Personal_Protection">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Personal Protection</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Personal_Protection}}
                                    </div>
                                </div>

                            </div>
                        </div> <div class="product_bg" *ngIf="Other_Information">
                            <div class="row ">
                                <div class="col-lg-6 col-xs-6">
                                    <div class="aboutFont">Other Information</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="casn">
                                        {{Other_Information}}
                                    </div>
                                </div>
                            </div>
                        </div>





                    </div>

                    <br />

                    <!--<div class="whatsapp">
                        <img src="../../../assets/whatsapp.jpg" class="icon_whatsapp" (click)="sendWhatsapp(Specdata[0].Structure)" />
                    </div>-->
                </div>

            </div>
            <!--<div class="col-lg-3">
                <div class="prodd">Documents</div>

                <div class="contact-form">
                    <div class="row">
                        <div class="col-lg-12">

                        </div><br />
                        <div class="col-lg-12">


                        </div>
                    </div>

                        <div class="col-lg-12">

                        </div>



                </div>
            </div>-->
            <div class="col-lg-8 dispnone ">


                <div class="row">
                    <!--<div class="col-lg-3">
                        <div class="prodd">  Available&nbsp;Packs</div>
                    </div>-->


                    <div class="col-lg-3 col-xs-6">
                        <div class="loader1" *ngIf="loading1"></div>
                        <!-- Pass Catalogue_Number value to GetSafetydata method when button is clicked -->
                        <button class="Custom"  (click)="GetSafetydata(Catalogue_Number)">Safety Data Sheet</button>
                    </div>

                    <div class="col-lg-5 col-xs-6">
                        <input type="search" placeholder="Search COA by BatchNo" [(ngModel)]="BatchNo" style="margin-top: 12px; border-radius: 5px;" />
                        <!--<div class="dropdown">
                            <p class="dropbtn" style="cursor:pointer;text-align:center">COA &nbsp;<i class="fa fa-caret-down" style="color:black"></i></p>
                            <div class="dropdown-content">
                                <a *ngFor="let coa of COApdfFiltered" (click)="GetCOApdf(coa.COA)" style="cursor:pointer;">{{ coa.COA }}</a>
                            </div>
                        </div>-->
                    </div>

                    <div class="col-lg-1 col-xs-6" style="margin-top: 9px; margin-left: -15px;">
                        <button class="Custom1" (click)="SearchandDownloadCOA(BatchNo)">Go</button>
                    </div>



                    <div class="col-lg-3 col-xs-6">
                        <div class="loader2" *ngIf="loading2"></div>
                        <button class="Custom1" (click)="GetSampleCOA(Sample_COA_Record_ID)">Sample COA</button>

                    </div>
                </div>


                <div class="contact-form">
                    <div class="loader" *ngIf="loading"></div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Packs</th>
                                <th scope="col">Availability</th>
                                <th scope="col" pTooltip="Discount" tooltipPosition="top" style="cursor:pointer">
                                    Price (₹)<br>
                                             <h6 *ngIf="loginDetails !==null"><i class="fa-solid fa-tags" ></i>&nbsp;{{ discus[0].Discount }}%</h6>
                                </th>



                                <th scope="col">Qty (No)</th>
                                <th scope="col">Cart</th>
                                <th scope="col">Favorites</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of sadata">
                                <td>{{item.PackName}}</td>
                                <td>
                                    <div class="availability-container">
                                        <button *ngIf="!stockFetched" (click)="checkAvailability(item)" class="availability-button">Check Availability</button>
                                        <span *ngIf="stockFetched">{{ item.Availability }}</span>
                                    </div>
                                </td>
                                <td *ngIf="loginDetails == null">
                                    <ng-container *ngIf="item.PackPrice.toFixed(2) == '0.00'; else showPrice">
                                        <strong>POR</strong>
                                    </ng-container>
                                    <ng-template #showPrice>
                                        {{ item.PackPrice.toFixed(2) }}
                                    </ng-template>
                                </td>

                                <td *ngIf="loginDetails !== null">
                                    <ng-container *ngIf="item.PackPrice !== 0; else porTemplate">
                                        <h6 [ngStyle]="{'text-decoration': loginDetails[0]?.Discount !== null ? 'line-through' : 'inherit', 'color': loginDetails[0]?.Discount !== null ? 'red' : 'inherit'}">
                                            {{ item.PackPrice | number:'1.2-2' }}
                                        </h6>
                                        <h6 *ngIf="loginDetails[0]?.Discount !== null">{{ discountstotal(item).toFixed(2) }}</h6>
                                    </ng-container>
                                    <ng-template #porTemplate>
                                        <h6>POR</h6>
                                    </ng-template>
                                </td>



                                <td>
                                    <div id="Quantity" class="quantiti quantity-button">
                                        <span (click)="decreaseQuantity(item)"
                                              [class.disabled]="item.Quantity <= 1"
                                              [style.cursor]="item.Quantity <= 1 ? 'not-allowed' : 'pointer'">-</span>
                                        <span style="margin-left: 7px;">{{ item.Quantity }}</span>
                                        <span (click)="increaseQuantity(item)"
                                              [class.disabled]="item.Quantity >= item.Availability || item.Quantity >= 10"
                                              [style.cursor]="item.Quantity >= item.Availability || item.Quantity >= 10 ? 'not-allowed' : 'pointer'"
                                              style="margin-left: 7px;">+</span>
                                    </div>
                                </td>
                                <td><i class="fa fa-cart-plus cart-icon" [ngClass]="{'disabled-icon': item.Availability === 'NA' || 0}" (click)="Addtocart(productDetails[0],item)"></i></td>
                                <td>
                                    <i *ngIf="!FavorateItem" class="far fa-bookmark" (click)="AddFav(productDetails[0],item)"></i>
                                    <i *ngIf="FavorateItem" class="far fa-bookmark" (click)="AddFav(productDetails[0],item)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row">
                        <div class="col-lg-5">
                            <button class="Custom2" (click)="toggleCustomEnquiry()">Custom Enquiry</button>
                        </div>
                        <div *ngIf="showCustomEnquiry" class="col-lg-7">
                            <div class="row">
                                <div class="col-lg-1"></div>
                                <div class="col-lg-6">
                                    <label>Qty<span style="color:red">*</span></label>
                                    <input class="add_input" type="text" placeholder="Enter Quantity" [(ngModel)]="Quantity1" />
                                </div>

                                <div class="col-lg-5">
                                    <label for="category">UOM<span style="color:red">*</span></label>
                                    <div class="add_input uomm">
                                        <p-autoComplete [(ngModel)]="UOM" [suggestions]="deptList" (completeMethod)="searchCont1($event)"
                                                        field="UOM" [dropdown]="true" [size]="90"
                                                        placeholder="Select UOM" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                        </p-autoComplete>
                                    </div>
                                    <small *ngIf="form.controls.UOM.touched && form.controls.UOM.errors?.required"
                                           class="p-error" style="color:red">UOM is required.</small>
                                </div>
                            </div>
                            <!--<div class="col-lg-9">
                <label>Remarks</label> <br />
                <textarea class="add_input" placeholder="Enter Remarks" [(ngModel)]="Comments"></textarea>
            </div>

            <div class="col-lg-3">
                <button class="btn btn-primary" (click)="InsertBulkorders(Quantity1, UOM, Comments)" [disabled]="!Quantity1 || !UOM" [ngClass]="{'disabled-button': !Quantity1 || !UOM}">Submit</button>
            </div>-->



                        </div>
                    </div>
                    <div class="row" *ngIf="showCustomEnquiry">

                        <div class="col-lg-9">
                            <label>Remarks</label><br />
                            <textarea class="add_input" placeholder="Enter Remarks" style="width: -webkit-fill-available;" [(ngModel)]="Comments"></textarea>
                        </div>

                        <div class="col-lg-3 text-right">
                            <button class="btn   btnn" (click)="InsertBulkorders(Quantity1, UOM, Comments)" [disabled]="!Quantity1 || !UOM || isButtonDisabled" [ngClass]="{'disabled-button': !Quantity1 || !UOM || isButtonDisabled}">Submit</button>
                        </div>

                    </div>

                </div>


            </div> 


            <div class="col-lg-6 cardnone" >
                <div class="row">
                  
                    <div class="col-lg-3 col-xs-6">
                       <button class="Custom" (click)="GetSafetydata(Catalogue_Number)">Safety Data Sheet</button>
                    </div>

                    <div class="col-lg-5 col-xs-6">
                        <input type="search" placeholder="Search COA by BatchNo" [(ngModel)]="BatchNo" style="margin-top: 12px; border-radius: 5px;" />
                    </div>

                    <div class="col-lg-1 col-xs-6">
                        <button class="Custom1" (click)="SearchandDownloadCOA(BatchNo)">Go</button>
                    </div>



                    <div class="col-lg-3 col-xs-6">

                        <button class="Custom1" (click)="GetSampleCOA(Catalogue_Number)">Sample COA</button>

                    </div>
                </div>
                <div class="card loader" *ngFor="let item of sadata">
                    <div class="row">

                        <div class="col-xs-8">
                            <div class="row">

                                <div class="col-12">
                                    <b>{{item.PackName}} </b>
                                    <div class="availability-container">
                                        <button *ngIf="!stockFetched" (click)="checkAvailability(item)" class="availability-button">Check Availability</button>
                                        <span *ngIf="stockFetched">{{item.Availability}}</span>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <b>
                                                <ng-container *ngIf="item.PackPrice.toFixed(2) == '0.00'; else showPrice">
                                                    <strong>POR</strong>
                                                </ng-container>
                                                <ng-template #showPrice>
                                                    {{ item.PackPrice.toFixed(2) }}
                                                </ng-template>
                                            </b>

                                           
                                        </div>
                                        <div class="col-xs-6">
                                            <div id="Quantity" class="quantiti quantity-button">
                                                <span (click)="decreaseQuantity(item)" style="cursor:pointer;">-</span>
                                                <span style="margin-left: 7px;">{{ item.Quantity }}</span>
                                                <span (click)="increaseQuantity(item)" style="margin-left: 7px; cursor:pointer;">+</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="col-xs-2">

                            <i class="fa fa-cart-plus cart-icon  text-right" (click)="Addtocart(productDetails[0],item)"></i>

                        </div>
                        <div class="col-xs-2  ">
                            <i *ngIf="!FavorateItem" class="far fa-bookmark  text-right" (click)="AddFav(productDetails[0],item)"></i>
                            <i *ngIf="FavorateItem" class="far fa-bookmark  text-right" (click)="AddFav(productDetails[0],item)"></i>
                        </div>

                    </div>
                </div>
                <div class="card" style="border-top:5px solid #285ea7;">
                    <div class="row">
                        <div class="col-lg-5">
                            <button class="Custom2" (click)="toggleCustomEnquiry()">Custom Enquiry</button>
                        </div>
                        <div *ngIf="showCustomEnquiry" class="col-lg-7">
                            <div class="row">
                                <div class="col-lg-1"></div>
                                <div class="col-lg-6 col-12">
                                    <label>Qty<span style="color:red">*</span></label>
                                    <input class="add_input" type="text" placeholder="Enter Quantity" [(ngModel)]="Quantity1" />
                                </div>

                                <div class="col-lg-5 col-12">
                                    <label for="category">UOM<span style="color:red">*</span></label>
                                    <div class="add_input uomm">
                                        <p-autoComplete [(ngModel)]="UOM" [suggestions]="deptList" (completeMethod)="searchCont1($event)"
                                                        field="UOM" [dropdown]="true" [size]="90"
                                                        placeholder="Select UOM" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                        </p-autoComplete>
                                    </div>
                                    <small *ngIf="form.controls.UOM.touched && form.controls.UOM.errors?.required"
                                           class="p-error" style="color:red">UOM is required.</small>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row" *ngIf="showCustomEnquiry">

                        <div class="col-lg-9 ">
                            <label>Remarks</label><br />
                            <textarea class="add_input" placeholder="Enter Remarks" style="width: -webkit-fill-available;" [(ngModel)]="Comments"></textarea>
                        </div>

                        <div class="col-lg-3 text-right">
                            <button class="btn   btnn" (click)="InsertBulkorders(Quantity1, UOM, Comments)" [disabled]="!Quantity1 || !UOM" [ngClass]="{'disabled-button': !Quantity1 || !UOM}">Submit</button>
                        </div>

                    </div>
                </div>
               
            </div>

        </div>
    </div>
    <br />

</div>

<style>
    * {
        box-sizing: border-box;
    }

    .img-zoom-container {
        position: relative;
    }

    .img-zoom-lens {
        position: absolute;
        border: 1px solid #d4d4d4;
        width: 40px;
        height: 40px;
    }

    .img-zoom-result {
        border: 1px solid #d4d4d4;
        width: 300px;
        height: 300px;
    }


    tr.break td {
        height: 10px;
    }
</style>

