import { Component, ViewChild, TemplateRef, AfterViewInit  } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ElementRef, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2';

import { CartService } from '../../Services/cart.service'
declare var $: any;


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {

    @ViewChild('myModal3') modalTemplate: TemplateRef<any>;
    @ViewChild('loader') loader: ElementRef | undefined;
    public form: FormGroup;
    arr: any = [];
    isLoggedIn: boolean = false;
    logindata: any;
    logindeta; any;
    Username: any;
    OTP: any;
    Pwd: any;
    HomeURL: any;
    Confirmpassword: any;
    Mobile: any;
    randomnumber: any;
    //Password: any;
    public EMailID: any;
    public PWD: AbstractControl;
    passwordShow: boolean = false;
    confirmPasswordShow: boolean = false;
    Phone1: any;
    email: any;
    Password: any;
    cartItems: any;
    token: any; otpSent: boolean = false;
    togglePasswordVisibility(field: string) {
        if (field === 'password') {
            this.passwordShow = !this.passwordShow;
        } else if (field === 'confirmPassword') {
            this.confirmPasswordShow = !this.confirmPasswordShow;
        }
    }

    constructor(public CartService: CartService, private generalservice: GeneralService, public http: HttpClient, private fb: FormBuilder,
        private router: Router, private elementRef: ElementRef, private renderer: Renderer2) {
        localStorage.removeItem("LoginDetails");
        this.logindeta = localStorage.getItem('Logindetals');
        this.form = this.fb.group({
            Phone1: ['',],
            Password: ['',],
            email: ['',],
        })
        this.email = this.form.controls['email'];
        this.Password = this.form.controls['Password'];
        this.CartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
    }

    ngOnInit() {
        const loginDetails = JSON.parse(localStorage.getItem('LoginDetails') || '{}');
        this.isLoggedIn = loginDetails.isLoggedIn || false;
        this.Username = loginDetails.Username || null;
    }
    openModal() {
        // Open the modal
        // You can implement your logic here to display the modal
        // For example, you can set a boolean flag to true
    }

    ngAfterViewInit() {
        // Ensure loader is defined before using it
        if (this.loader) {
            this.hideLoader(); // Initially hide the loader
        } else {
            console.error('Loader element not found.');
        }
    }

    // Methods to show and hide the loader
    showLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'block';
        } else {
            console.error('Loader element not found.');
        }
    }

    hideLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'none';
        } else {
            console.error('Loader element not found.');
        }
    }
    logout() {

        this.isLoggedIn = false;
        this.Username = null;

        // Clear localStorage
        localStorage.removeItem('LoginDetails');
    }

    Close() {
        const modal4 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal4, 'display', 'none');
        const modal5 = this.elementRef.nativeElement.querySelector('#myModal3');
        this.renderer.setStyle(modal5, 'display', 'none');
        const modal6 = this.elementRef.nativeElement.querySelector('#myModal4');
        this.renderer.setStyle(modal6, 'display', 'none');
    }


    // Methods to show and hide the loader
   

    public OnSubmit(): void {
       
        this.showLoader(); // Show loader at the start of the submission process
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                Phone1: this.form.value.email,
                PWD: this.encodeBase64(this.form.value.Password),
            });

            if (this.form.valid) {
                var uploadfile = new FormData();
                uploadfile.append("Param1", this.form.value.email);
                uploadfile.append("Param2", this.encodeBase64(this.form.value.Password));
                var url = this.HomeURL + "api/Azole/Azl_Customer_Login";
                var accessToken = this.token; // Use the token obtained from Gettoken

                // Set the Authorization header with the access token
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${accessToken}` // Prefix "Bearer" is a common convention for JWT tokens
                });

                // Use HttpHeaders in the request
                this.http.post(url, uploadfile, { headers }).subscribe(
                    (data: any) => {
                      
                        this.hideLoader(); // Hide loader after the request completes

                        if (data != null) {
                            if (data == "Invalid User") {
                                this.generalservice.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Please enter valid credentials, If you are a new user please sign up', '');
                                this.router.navigate(['/Registration']);
                                this.isLoggedIn = false;
                            } else {
                                localStorage.setItem("LoginDetails", JSON.stringify(data));

                                if (this.cartItems === undefined || !this.cartItems || this.cartItems.length === 0) {
                                    this.router.navigateByUrl('/navbar').then(() => {
                                        this.router.navigate(['/navbar']).then(() => {
                                            window.location.reload();
                                        });
                                    });
                                } else {
                                    this.router.navigate(['/Cart']).then(() => {
                                        window.location.reload();
                                    });
                                }
                            }
                        } else {
                            // Handle the case where data is null
                            this.generalservice.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Please enter valid credentials, If you are a new user please sign up', '');
                            this.router.navigate(['/Registration']);
                            this.isLoggedIn = false;
                        }
                    }, (err: any) => {
                        this.hideLoader(); // Hide loader if there is an error
                        this.generalservice.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');
                        this.isLoggedIn = false;
                    });
            }
        }).catch(() => {
            this.hideLoader(); // Hide loader if there is an error in getting the token
        });
    }


    private encodeBase64(input: string): string {
        return btoa(input); // btoa() encodes a string in Base64
    }

    ForgotPassword() {
        debugger
        this.EMailID = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'block');
        this.CheckMobile(this.EMailID);
    }

    CheckMobile(EMailID) {
    
        EMailID: EMailID

        if (EMailID == undefined || EMailID == "" || EMailID == null) {
        
            //alert("Please Enter EmailID")
            // this.openToast();
        }
        else {

            var UploadFile = new FormData();
            UploadFile.append("Mobile", EMailID);
            //UploadFile.append("Flag", "1");
            var url = "api/Azole/checking_Mobile"
         
            this.generalservice.PostData(url, UploadFile).then(data => {
              
                if (data != 'NOTEXIST') {
                    
                    this.EMailID = data[0].EMailID;
                    this.OTP = ""
                    this.Phone1 = EMailID;
                    this.SendOtpForMobile();
                    this.generalservice.ShowAlert('SUCCESS', 'Email validated successfully. Please enter the OTP sent to your email.', 'success');
                    const modal2 = this.elementRef.nativeElement.querySelector('#myModal2');
                    this.renderer.setStyle(modal2, 'display', 'none');

                    // Show myModal3
                    const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal3, 'display', 'block');

                }
                else {

                    this.generalservice.ShowAlert('WARNING', 'Please enter a valid email address.', 'warning');
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var url = 'api/Azole/SendOTPtoMail?EmailId=' + this.EMailID + '&OTP=' + this.randomnumber + '&Subject=OTP to reset password';
        this.generalservice.GetData(url).then(data => {
            if (data == "SUCCESS") {

            }
            else {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        });
    }

    SendOTPtoMail() {

        var Content = "Your OTP is: " + this.OTP;
        var UploadFile = new FormData();
        UploadFile.append("EMailID", this.EMailID);
        UploadFile.append("Content", Content);
        var url = "api/Azole/SendOTPtoMail";
        this.generalservice.PostData(url, UploadFile).then((data: any) => {

        })
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
         
        if (this.randomnumber == otp) {
            const modal3 = this.elementRef.nativeElement.querySelector('#myModal3');
            this.renderer.setStyle(modal3, 'display', 'none');

            const modal = this.elementRef.nativeElement.querySelector('#myModal4');
            this.renderer.setStyle(modal, 'display', 'block');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/

    /*Change password*/
    ChangePassword(PWD, confirmpassword) {
        if (PWD == undefined || PWD == null || PWD == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (PWD != confirmpassword) {
                alert("password and confirm Password must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Password", this.encodeBase64(PWD));
                UploadFile.append("Mobile", this.EMailID);
                var url = "api/Azole/Forgot_Password"
                
                this.generalservice.PostData(url, UploadFile).then(data => {
                    
                    if (data == 'SUCCESS') {
                        this.generalservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', ' Password Changed Successfully.', '')
                        const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                        this.renderer.setStyle(modal, 'display', 'none');
                    }
                }, err => {
                    const modal = this.elementRef.nativeElement.querySelector('#myModal4');
                    this.renderer.setStyle(modal, 'display', 'none');
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }

    //public onSubmit(): void {
    //    this.arr = [];
    //    this.arr.push({
    //        EMail: this.form.value.EMail,
    //        password: this.form.value.password,
    //    });
     
    //    if (this.form.valid) {
    //        var formData = new FormData();
    //        formData.append("Param", JSON.stringify(this.arr));
    //        var url = "api/SYCar/SYC_Login";
    //        this.generalService.PostData(url, formData)
    //            .then((data: any) => {
                 
    //                if (data != null) {
    //                    if (data == "Invalid User") {
    //                        //   this.logindata = data;
    //                        this.generalService.ShowAlert("Warning", 'Please Enter Valid Credentials', 'Warning');
    //                    } else {
    //                        localStorage.setItem("LoginDetails", JSON.stringify(data));
    //                        this.router.navigate(['/dashboard']);
    //                    }
    //                }
    //            })
    //            .catch((error: any) => {
    //                console.error("Error occurred:", error);
    //                // Handle error appropriately, show error message to the user
    //                this.generalService.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
    //            });
    //    }
    //}
}








